<template>
  <div style="position: relative; z-index: 1">
    <!-- nava -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>基本信息</el-breadcrumb-item>
      <el-breadcrumb-item>机构信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 非空状态 -->
    <div v-if="isem == 1">
      <!-- top-title -->
      <div class="top-title">
        <div class="left">
          <div class="blueTitle" style="margin-left: 12px">基本信息</div>
        </div>
        <div class="right" v-if="orgDetailForm">
          <div class="status">
            <span class="top-status">机构账户状态</span
            ><span class="top-open">{{ orgDetailForm.status }}</span>
          </div>
          <div class="status">
            <span class="top-status">创建时间</span
            ><span class="top-open">{{ orgDetailForm.createTime }}</span>
          </div>
        </div>
      </div>
      <!-- main-box -->
      <div class="mian-info-side">
        <div class="mian-info">
          <div class="top-logo">
            <div class="upload">
              <span class="upload-title">机构图片</span>
              <!-- 无图片时 -->
              <div class="upload-img" v-if="orgImg == null">
                <img class="img" :src="uploadImg" />
                <el-upload
                  ref="upload1"
                  class="up-tip"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload1"
                  :http-request="httprequest1"
                >
                  <span class="up-tip">点击上传</span>
                </el-upload>
              </div>
              <!-- 有图片时 -->
              <div class="upload-imgH" v-if="orgImg != null">
                <img class="imgH" :src="orgImg" />
                <el-upload
                  ref="upload1"
                  class="up-tipH"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload1"
                  :http-request="httprequest1"
                >
                  <span class="up-tipH">重新上传</span>
                </el-upload>
              </div>
              <span class="upload-tip">png格式，500*500px，5M以内</span>
            </div>
            <div class="upload">
              <span class="upload-title">机构Logo</span>
              <!-- 无图片 -->
              <div class="upload-img" v-if="orgLogo == null">
                <img class="img" :src="uploadImg" />
                <el-upload
                  ref="upload2"
                  class="up-tip"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload2"
                  :http-request="httprequest2"
                >
                  <span class="up-tip">点击上传</span>
                </el-upload>
              </div>
              <!-- 有图片 -->
              <div class="upload-imgH" v-if="orgLogo != null">
                <img class="imgH" :src="orgLogo" />
                <el-upload
                  ref="upload2"
                  class="up-tipH"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload2"
                  :http-request="httprequest2"
                >
                  <span class="up-tipH">重新上传</span>
                </el-upload>
              </div>
              <span class="upload-tip">png格式，500*500px，5M以内备份</span>
            </div>

            <div class="upload">
              <span class="upload-title">报告单二维码</span>
              <!-- 无图片时 -->
              <div class="upload-img" v-if="orgQrCode == null">
                <img class="img" :src="uploadImg" />
                <el-upload
                  ref="upload1"
                  class="up-tip"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload3"
                  :http-request="httprequest3"
                >
                  <span class="up-tip">点击上传</span>
                </el-upload>
              </div>
              <!-- 有图片时 -->
              <div class="upload-imgH" v-if="orgQrCode != null">
                <img class="imgH" :src="orgQrCode" />
                <el-upload
                  ref="upload1"
                  class="up-tipH"
                  :limit="1"
                  action=""
                  :show-file-list="false"
                  :auto-upload="true"
                  :before-upload="beforeUpload3"
                  :http-request="httprequest3"
                >
                  <span class="up-tipH">重新上传</span>
                </el-upload>
              </div>
              <span class="upload-tip">png格式，500*500px，5M以内</span>
            </div>

            <div class="upload">
              <span
                class="upload-title-org"
                @mousemove="viewOrgCode"
                @mouseleave="moveout"
                >机构二维码</span
              >
            </div>
          </div>
          <!-- addr :rules="rules" -->
          <div class="form">
            <div class="form-top">
              <div class="form-left">
                <div class="f-top">
                  <span
                    style="margin-right: 60px; font-size: 16px; color: #323233"
                    >机构地区</span
                  >
                  <el-cascader
                    style="width: 290px"
                    clearable
                    :value="cityName"
                    :options="district"
                    :props="{ expandTrigger: 'hover' }"
                    @change="selectCity"
                  ></el-cascader>
                </div>
                <div class="f-bottom">
                  <span
                    style="margin-right: 77px; font-size: 16px; color: #323233"
                    >负责人</span
                  >
                  <el-input
                    v-model="formData.firstPerson"
                    style="width: 156px"
                  ></el-input>
                </div>
                <div class="f-bottom">
                  <span
                    style="margin-right: 77px; font-size: 16px; color: #323233"
                    >是否支持小程序预约</span
                  >
                  <el-switch active-color="#00cccc" v-model="formData.value">
                  </el-switch>
                </div>
              </div>
              <div class="form-right">
                <div class="f-top">
                  <span
                    style="margin-right: 60px; font-size: 16px; color: #323233"
                    >详细地址</span
                  >
                  <el-input
                    v-model="formData.addr"
                    maxlength="34"
                    show-word-limit
                    style="width: 410px"
                  ></el-input>
                </div>
                <div class="f-bottom">
                  <span
                    style="margin-right: 60px; font-size: 16px; color: #323233"
                    >联系方式</span
                  >
                  <el-input
                    v-model="formData.mobile"
                    style="width: 188px"
                  ></el-input>
                </div>
              </div>
            </div>
            <!--编辑 机构简介 -->
            <div class="edit">
              <div
                style="
                  margin-right: 54px;
                  font-size: 16px;
                  color: #323233;
                  margin-top: 12px;
                  width: 84px;
                "
              >
                机构简介
              </div>
              <div class="edit-box" style="min-height: 60px !important">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 500px; overflow-y: hidden"
                  v-model="html"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                />
              </div>
            </div>
            <!--编辑 预约须知 -->
            <div class="edit">
              <div
                style="
                  margin-right: 54px;
                  font-size: 16px;
                  color: #323233;
                  margin-top: 12px;
                  width: 84px;
                "
              >
                预约须知
              </div>
              <div class="edit-box">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor2"
                  :defaultConfig="toolbarConfig2"
                  :mode="mode2"
                />
                <Editor
                  style="height: 500px; overflow-y: hidden"
                  v-model="html2"
                  :defaultConfig="editorConfig2"
                  :mode="mode2"
                  @onCreated="onCreated2"
                />
              </div>
            </div>
          </div>
          <!-- save -->
          <div class="submit" @click="save">保存</div>

          <!-- 悬浮弹框 -->
          <div
            class="code-code"
            v-if="isMove"
            @mouseover="movein1"
            @mouseleave="movein2"
          >
            <div class="img-box">
              <img class="img-code" :src="codeImg1" />
              <img class="img-code" :src="codeImg2" />
              <img class="img-code" :src="codeImg3" />
            </div>
            <div class="dowload" @click="getAllCodeImg">下载</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 空状态 -->
    <div v-if="isem == 2" class="emty">
      <div class="emptyBox">
        <img class="emimg" :src="emptyImg" />
        <span class="tip-text">您的账号暂未与任何机构绑定，详细可联系</span>
        <span class="tip-text">零动医疗科技有限公司相关人员。</span>
      </div>
    </div>
  </div>
</template>

<script>
import { district } from '../../utils/cityList.js';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { viewOrg, viewOrgCode1, viewOrgCode2 } from '@/api/orgController.js';
import { updateOrg, getSignature, getHospitalList } from '@/api/order.js';
import { getFolderLastName } from '@/utils/uploadfun.js';
import QRCode from 'qrcode';
import JSZip from 'jszip';
import FileSaver from 'file-saver'; //不能删
import axios from 'axios';

export default {
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  components: {
    Editor,
    Toolbar
  },
  data() {
    return {
      isem: 0, //
      emptyImg: 'https://sz.drbodyhealth.com/emptybox.png',
      isEdit: false, //是否属于编辑状态
      orgId: null,
      orgDetailForm: null, //查询机构信息
      orgNo: null, //报告编号
      timer: null,
      signa: null, //签名

      //富文本编辑配置1--预约
      editor: null,
      html: '', //上传到后台编辑好的html文本
      toolbarConfig: {}, //自定义配置tabbar
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'

      //富文本编辑配置2
      editor2: null,
      html2: '', //上传到后台编辑好的html文本
      toolbarConfig2: {}, //自定义配置tabbar
      editorConfig2: { placeholder: '请输入内容...' },
      mode2: 'default', // or 'simple'

      //其它
      district,
      cityName: 'ihuhu',
      isMove: false,
      uploadImg: 'https://sz.drbodyhealth.com/uploadImg.png',
      formData: {
        cityCode: null,
        proCode: null,
        addr: null,
        firstPerson: null,
        mobile: null,
        value: false
      },
      orgImg: null,
      orgLogo: null,
      orgQrCode: null,
      //二维码信息
      base64Img1: null,
      codeImg1: null,
      base64Img2: null,
      codeImg2: null,
      base64Img3: null,
      codeImg3: null
    };
  },
  created() {
    //根据个人token来查--不根据id来查
    var token = localStorage.getItem('token');
    if (token) {
      this.getCurentOrg();
    }
  },
  mounted() {
    this.getSigna(); //获取签名
  },
  methods: {
    selectCity() {
      this.formData.cityCode = city[1];
      this.formData.proCode = city[0];
    },
    //鼠标滑倒
    viewOrgCode() {
      this.isMove = true;
    },
    //鼠标划出
    moveout() {
      this.timer = setTimeout(() => {
        this.isMove = false;
      }, 600);
    },
    movein1() {
      //console.log("ss")
      clearTimeout(this.timer);
      this.isMove = true;
      this.timer = null;
    },
    movein2() {
      this.isMove = false;
    },
    //
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onCreated2(editor) {
      this.editor2 = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    //具体id查询-info
    viewById(id) {
      viewOrg({
        id: id // token来查
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.orgDetailForm = res.data.data;
            //回显
            this.orgNo = res.data.data.orgCode;
            this.getCode3(this.orgNo); //设局二维码
            if (res.data.data.orgDesc != null) {
              this.html = res.data.data.orgDesc; //机构简介回显
            }
            if (res.data.data.remarks != null) {
              this.html2 = res.data.data.remarks; //预约须知回显
            }
            if (res.data.data.orderStatus != null) {
              if (res.data.data.orderStatus == true) {
                this.formData.value = true;
              } else {
                this.formData.value = false;
              }
            }
            if (res.data.data.orgMainImg != null) {
              this.orgImg = res.data.data.orgMainImg;
            }
            if (res.data.data.orgLogo != null) {
              this.orgLogo = res.data.data.orgLogo;
            }
            if (res.data.data.orgQrCode != null) {
              this.orgQrCode = res.data.data.orgQrCode;
            }
            if (res.data.data.orgCityCode != null) {
              this.cityName = [
                res.data.data.orgProvinceCode,
                res.data.data.orgCityCode
              ];
              this.formData.cityCode = res.data.data.orgCityCode;
              this.formData.proCode = res.data.data.orgProvinceCode;
            }
            if (res.data.data.orgAddress != null) {
              this.formData.addr = res.data.data.orgAddress;
            }
            if (res.data.data.orgContactsName != null) {
              this.formData.firstPerson = res.data.data.orgContactsName;
            }
            if (res.data.data.orgEmail != null) {
              this.formData.mobile = res.data.data.orgEmail;
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },

    //保存--提交
    save() {
      updateOrg({
        id: this.orgId,
        orgAddress: this.formData.addr,
        orgCityCode: this.formData.cityCode,
        orgProvinceCode: this.formData.proCode,
        orgContactsName: this.formData.firstPerson,
        orgContactsMobile: this.formData.mobile,
        orgDesc: this.html,
        remarks: this.html2,
        orderStatus: String(this.formData.value)
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            message: '更新成功！',
            type: 'success'
          });
          this.viewById(this.orgId); //frsh
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    //获取二维码信息
    //ai在线检测二维码
    getCode1(id) {
      viewOrgCode1({
        id: id // token来查
      }).then((res) => {
        this.base64Img1 = res.data;
        this.codeImg1 = window.URL.createObjectURL(res.data);
      });
    },
    //社区筛查检测机构二维码
    getCode2(id) {
      viewOrgCode2({
        id: id // token来查
      }).then((res) => {
        this.base64Img2 = res.data;
        this.codeImg2 = window.URL.createObjectURL(res.data);
      });
    },
    //ai在线检测二维码
    getCode3(qrdata) {
      var url = this.qrcode(qrdata);
      this.codeImg3 = url;
      let base64 = {
        dataURL: url, //用url方式表示的base64图片数据
        type: 'image/jpeg' //文件类型
      };
      this.base64Img3 = this.convertBase64UrlToBlob(base64);
    },
    //生成二维码
    qrcode(qrdata) {
      var URL = null;
      QRCode.toDataURL(
        qrdata,
        { errorCorrectionLevel: 'L', margin: 2, width: 128 },
        (err, url) => {
          if (err) throw err;
          URL = url;
        }
      );
      return URL;
    },
    //base64转blob
    convertBase64UrlToBlob(base64) {
      let urlData = base64.dataURL;
      let type = base64.type;
      let bytes = null;
      if (urlData.split(',').length > 1) {
        //是否带前缀
        bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
      } else {
        bytes = window.atob(urlData);
      }
      // 处理异常,将ascii码小于0的转换为大于0
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    //一键导出为二维码
    getAllCodeImg() {
      const arrImages = [
        { name: 'ai在线检测机构二维码', url: this.base64Img1 },
        { name: '社区筛查检测机构二维码', url: this.base64Img2 },
        { name: '检测任务二维码', url: this.base64Img3 }
      ];
      const zip = new JSZip();
      arrImages.forEach((item) => {
        zip.file(item.name + '.png', item.url, { base64: true });
      });
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'codeImg.zip');
      });
    },

    //上传图片
    //1
    beforeUpload1(file) {
      // console.log("上传",file) ;
      var msg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const fileType1 = msg === 'jpg';
      const fileType2 = msg === 'png';
      if (!fileType1 && !fileType2) {
        this.$message({
          type: 'warning',
          message: '只能上传png/jpg文件'
        });
        return fileType;
      }
    },

    httprequest1(par) {
      //console.log("par",par)
      var uploadFile = par.file;
      var filename = par.file.name;
      var type = par.file.type;
      var postfix = getFolderLastName(filename); //文件后缀
      var folder = this.signa.folder;
      const key = `${folder}.${postfix}`; // 文件名+后缀//文件名+后缀//不加斜杠
      let ossUrl = this.signa.host; //填写自己OSS服务器的地址
      var formData = new FormData();
      formData.append('OSSAccessKeyId', this.signa.accessId);
      formData.append('policy', this.signa.policy);
      formData.append('Signature', this.signa.signature);
      formData.append('key', key); //上传的文件路径
      formData.append('success_action_status', 200); // 指定返回的状态码
      formData.append('x-oss-content-type', type);
      formData.append('file', uploadFile);
      // console.log('last',formData);
      axios.post(ossUrl, formData).then((res) => {
        //console.log("res",res);
        //上转成功后更新
        updateOrg({
          id: this.orgId,
          orgMainImg: key
        }).then((res) => {
          if (res.data.status == 200) {
            this.$message({
              message: '上传成功！',
              type: 'success'
            });
            this.viewById(this.orgId); //frsh
            this.getSigna(); //再次获取签名
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },

    //2
    beforeUpload2(file) {
      var msg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const fileType1 = msg === 'jpg';
      const fileType2 = msg === 'png';
      if (!fileType1 && !fileType2) {
        this.$message({
          type: 'warning',
          message: '只能上传png/jpg'
        });
        return fileType;
      }
    },

    httprequest2(par) {
      var uploadFile = par.file;
      var filename = par.file.name;
      var type = par.file.type;
      var postfix = getFolderLastName(filename); //文件后缀
      var folder = this.signa.folder;
      var key2 = `${folder}.${postfix}`; // 文件名+后缀//文件名+后缀//不加斜杠
      var ossUrl = this.signa.host; //填写自己OSS服务器的地址
      var formData = new FormData();
      formData.append('OSSAccessKeyId', this.signa.accessId);
      formData.append('policy', this.signa.policy);
      formData.append('Signature', this.signa.signature);
      formData.append('key', key2); //上传的文件路径
      formData.append('success_action_status', 200); // 指定返回的状态码
      formData.append('x-oss-content-type', type);
      formData.append('file', uploadFile);
      // console.log('last',formData);
      axios.post(ossUrl, formData).then((res) => {
        //console.log("res",res);
        //上转成功后更新
        updateOrg({
          id: this.orgId,
          orgLogo: key2
        }).then((res) => {
          if (res.data.status == 200) {
            this.$message({
              message: '上传成功！',
              type: 'success'
            });
            this.viewById(this.orgId); //frsh
            this.getSigna(); //再次获取签名
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },

  //上传图片
  //1
  beforeUpload3(file) {
    // console.log("上传",file) ;
    var msg = file.name.substring(file.name.lastIndexOf('.') + 1);
    const fileType1 = msg === 'jpg';
    const fileType2 = msg === 'png';
    if (!fileType1 && !fileType2) {
      this.$message({
        type: 'warning',
        message: '只能上传png/jpg文件'
      });
      return fileType;
    }
  },

  httprequest3(par) {
    //console.log("par",par)
    var uploadFile = par.file;
    var filename = par.file.name;
    var type = par.file.type;
    var postfix = getFolderLastName(filename); //文件后缀
    var folder = this.signa.folder;
    const key = `${folder}.${postfix}`; // 文件名+后缀//文件名+后缀//不加斜杠
    let ossUrl = this.signa.host; //填写自己OSS服务器的地址
    var formData = new FormData();
    formData.append('OSSAccessKeyId', this.signa.accessId);
    formData.append('policy', this.signa.policy);
    formData.append('Signature', this.signa.signature);
    formData.append('key', key); //上传的文件路径
    formData.append('success_action_status', 200); // 指定返回的状态码
    formData.append('x-oss-content-type', type);
    formData.append('file', uploadFile);
    // console.log('last',formData);
    axios.post(ossUrl, formData).then((res) => {
      //console.log("res",res);
      //上转成功后更新
      updateOrg({
        id: this.orgId,
        orgQrCode: key
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            message: '上传成功！',
            type: 'success'
          });
          this.viewById(this.orgId); //frsh
          this.getSigna(); //再次获取签名
        } else {
          this.$message.error(res.data.message);
        }
      });
    });
  },
    //自动获取当前机构信息
    getCurentOrg() {
      getHospitalList({
        //当前该账户下以默认绑定有的
      }).then((res) => {
        if (res.data.status == 200) {
          //console.log("org",res.data.data);
          this.isem = 1;
          var id = res.data.data.id;
          this.orgId = res.data.data.id;
          this.viewById(id); //base-info
          this.getCode1(id); //code-1
          this.getCode2(id); //code-2
        } else {
          this.isem = 2;
          this.$message.error(res.data.message);
        }
      });
    },

    //获取签名
    getSigna() {
      getSignature({})
        .then((res) => {
          if (res.data.status == 200) {
            this.signa = res.data.data;
          }
        })
        .catch((erro) => {
          console.log('catch-erro', erro);
        });
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    const editor2 = this.editor2;
    if (editor == null || editor2 == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
    editor2.destroy(); // 组件销毁时，及时销毁编辑器
  },

};
</script>
<style scoped lang="stylus">
/*富文本编辑样式 */
@import '~@/assets/css/style.css'
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__inner{
    border: 1px solid #00cccc !important;
}
/deep/.el-textarea__inner{
    border: 1px solid #00cccc !important;
}

/*空状态 */
.emty
    width: 100%
    height: 100vh
    display: flex
    flex-direction: columen
    align-items: center
    .emptyBox
        width:310px
        height:300px
        margin:0 auto
        text-align: center
        .emimg
            width: 260px
            height: 250px
        .tip-text
            display: block
            font-size:16px
            color:#333333


//component style
.code-code
    width: 400px
    height: 210px
    position: absolute
    right: 77px
    top:200px
    z-index:99
    background-color: #fff
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.0800);
    border-radius: 4px
    .img-box
        display: flex
        margin-top: 32px
        justify-content: space-evenly
        .img-code
            width:100px
            height:100px
            display: block
    .dowload
        width: 120px
        height: 32px
        background-color: #00cccc
        color: #fff
        font-size: 14px
        line-height: 32px
        text-align: center
        margin: 32px auto
        border-radius: 4px
        cursor pointer


.blueTitle
  font-size: 18px
  color: #353535
  display: flex
  align-items: center
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:2px
    background-color: #00CCCC

//normal style
.top-title
    display: flex
    justify-content: space-between
    margin-top: 36px
    .right
        display: flex
        .status
            display: flex
            margin-right: 110px
            .top-status
                font-size:16px
                color: #323233
            .top-open
                font-size: 16px
                color: #666666
                margin-left: 16px
.mian-info-side
    margin-top: 20px
    width: 100%
    box-sizing: border-box
    padding-right: 80px
    .mian-info
        width: 100%
        box-sizing: border-box
        border: 1px solid #CEF8F8
        padding: 40px 60px 40px 144px
        .top-logo
            width: 100%
            height: 300px
            display: flex
            justify-content: space-between
            .upload
                overflow hidden
                .upload-title
                    color: #323233
                    font-size: 16px
                .upload-img
                    width: 180px
                    height: 180px
                    margin-top: 18px
                    border-radius: 10px
                    border: 1px solid #00CCCC
                    display: flex
                    flex-direction: column
                    align-items: center
                    .img
                        width: 124px
                        height: 100px
                        margin-top: 25px

                    .up-tip
                        display: block
                        width: 180px
                        height: 38px
                        text-align: center
                        margin-top: 15px
                        color: #00cccc
                        cursor pointer
                        font-size: 16px
                .upload-imgH
                    width: 180px
                    height: 180px
                    margin-top: 18px
                    position: relative
                    border: 1px solid #00CCCC
                    display: flex
                    flex-direction: column
                    align-items: center
                    .imgH
                        position: absolute
                        left: 0
                        top:0
                        width: 180px
                        height: 180px
                        object-fit: over
                        border-radius:0 0 10px 10px

                    .up-tipH
                        display: block
                        width: 180px
                        height: 38px
                        text-align: center
                        cursor pointer
                        line-height: 38px
                        border-radius:0 0 10px 10px
                        position:absolute
                        background: linear-gradient(180deg, #00CCCC 0%, rgba(233,255,255,0) 100%)
                        left: 0
                        bottom:0
                        color: #fff
                        font-size: 16px

                .upload-tip
                    font-size: 12px
                    color: #666666
                    display: block
                    margin-top: 14px
                .upload-title-org
                    display: block
                    width: 120px
                    height: 35px
                    text-align: center
                    cursor pointer
                    font-size: 16px
                    color: #666666
                    line-height: 35px
                    border-radius: 4px
                    border: 1px solid #999999
        .form
            overflow hidden
            box-sizing: border-box
            width: 100%
            padding-right: 2%
            .form-top
                display: flex
                margin-top: 80px
                justify-content: space-between
                .form-left
                    overflow hidden
                    .f-top
                        overflow hidden
                    .f-bottom
                        margin-top: 48px
                .form-right
                    overflow hidden
                    .f-top
                        overflow hidden
                    .f-bottom
                        margin-top: 48px
            .edit
                display: flex
                margin-top: 48px
                .edit-box
                    width: 100%
                    min-height: 100px
                    border: 1px solid #00cccc
                    border-radius: 24rpx
        .submit
            width: 160px
            height: 44px
            background-color: #00cccc
            border-radius: 6px
            cursor pointer
            font-size: 18px
            text-align: center
            line-height: 44px
            color: #fff
            margin: 40px auto 0 auto
</style>
